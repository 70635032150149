.active {
  color: #6096ff !important;
}

.overview-nav-group div span {
  border-bottom: 3px solid #0e2346 !important;
  display: inline-block;
}

.overview-nav-group .active div span {
  border-bottom: 3px solid #6096ff !important;
  display: inline-block;
}

.overview-nav-group a:hover div span {
  border-bottom: 3px solid #6096ff !important;
  display: inline-block;
}

.overview-nav-group .external-nav {
  text-decoration: none;
}

.overview-nav-group .external-nav:hover {
  border-bottom: 3px solid #6096ff !important;
  text-decoration: none;
}

p {
  font-size: 14px;
  line-height: 1.3;
}

a {
  color: #6096ff;
}

a:hover {
  cursor: pointer;
}

button {
  font-family: "Barlow", "Helvetica", "-apple-system";
}

/* Home Overview Donut Charts */
.donut-count {
  font-size: 11px;
}
